<template>
  <div>
    <v-row align="center">
      <v-col cols="auto" class="pr-12">
        <h2>Pricing</h2>
      </v-col>
      <v-col>
        <v-row align="center" justify="end" no-gutters>
          <v-btn
            small
            depressed
            color="green white--text"
            @click="$refs.priceForm.openForm()"
          >
            <v-icon small left>mdi-plus</v-icon> Add Price
          </v-btn>
        </v-row>
      </v-col>
    </v-row>
    <v-sheet outlined>
      <v-data-table
        :headers="tableHeaders"
        :items="prices"
        no-data-text="There have been no prices added"
      >
        <template v-slot:item.rate="{ item }"> £{{ item.amount }} </template>
        <template v-slot:item.status="{ item }">
          <v-chip
            :color="getStatusColor(item.status)"
            text-color="white"
            label
            small
            >{{ getStatusText(item.status) }}</v-chip
          >
        </template>
        <template v-slot:item.actions="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                x-small
                depressed
                color="blue lighten-4 blue--text"
                class="mr-2"
                v-on="on"
                @click="$refs.priceForm.openForm(item)"
              >
                <v-icon x-small>mdi-pencil</v-icon>
              </v-btn>
            </template>
            <span>Edit</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                x-small
                depressed
                color="red lighten-4 red--text"
                v-on="on"
                @click="openDelete(item)"
              >
                <v-icon x-small>mdi-archive</v-icon>
              </v-btn>
            </template>
            <span>Archive</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-sheet>
    <v-divider class="mt-6 mb-6"></v-divider>
    <h3 class="pb-3">Old Prices</h3>
    <v-sheet outlined>
      <v-data-table
        :headers="tableHeaders"
        :items="oldPrices"
        no-data-text="There are no old prices"
      >
        <template v-slot:item.rate="{ item }"> £{{ item.amount }} </template>
        <template v-slot:item.status="{ item }">
          <v-chip
            :color="getStatusColor(item.status)"
            text-color="white"
            label
            small
            >{{ getStatusText(item.status) }}</v-chip
          >
        </template>
        <template v-slot:item.actions="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                x-small
                depressed
                color="blue lighten-4 blue--text"
                class="mr-2"
                v-on="on"
                @click="$refs.priceForm.openForm(item)"
              >
                <v-icon x-small>mdi-pencil</v-icon>
              </v-btn>
            </template>
            <span>Edit</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                x-small
                depressed
                color="red lighten-4 red--text"
                v-on="on"
                @click="openDelete(item)"
              >
                <v-icon x-small>mdi-archive</v-icon>
              </v-btn>
            </template>
            <span>Archive</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-sheet>
    <v-dialog v-model="deleteDialog.open" persistent max-width="290">
      <v-card>
        <v-card-title class="headline">Delete Price</v-card-title>
        <v-card-text>Are you sure you want to delete this price?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" depressed @click="resetDelete">No, cancel</v-btn>
          <v-btn
            color="success"
            depressed
            :loading="deleteDialog.loading"
            @click="saveDelete"
            >Yes</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="warningDialog.open" max-width="500px" persistent>
      <v-card>
        <v-card-title class="headline">Itinerary Status Change</v-card-title>
        <v-card-text>
          <p>
            By removing this price, this Itinerary's status will be changed to
            <strong>Draft</strong>.
          </p>
          <p v-if="tour.status === 'tour'">
            This Tour will also change back to a <strong>Proposal</strong>
          </p>
          <p class="mb-0">Do you agree to these changes?</p>
        </v-card-text>
        <v-card-actions class="pl-6 pr-6 pb-6">
          <v-btn color="error" depressed @click="confirmWarning(false)"
            >No</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn color="success" depressed @click="confirmWarning(true)"
            >Yes</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <PriceForm ref="priceForm" />
  </div>
</template>

<script>
import PriceForm from "../../components/PriceForm";
import _ from "lodash";

export default {
  components: {
    PriceForm,
  },

  data() {
    return {
      tableHeaders: [
        { text: "Description", value: "description" },
        { text: "Amount", value: "rate" },
        { text: "Quantity", value: "quantity" },
        { text: "Status", value: "status" },
        { text: "Actions", value: "actions", sortable: false, align: "right" },
      ],
      deleteDialog: {
        open: false,
        loading: false,
        price: {},
        fields: {
          changeStatus: false,
        },
      },
      warningDialog: {
        open: false,
        loading: false,
        confirmed: false,
      },
    };
  },

  computed: {
    itinerary() {
      return this.$store.state.drum.tours["itinerary"];
    },
    tour() {
      return this.$store.state.drum.tours["tour"];
    },
    prices() {
      let pricing = this.itinerary.pricing;
      if (pricing) {
        pricing = pricing.filter(p => p.status !== "Rejected");
      }
      return pricing;
    },
    oldPrices() {
      let pricing = this.itinerary.pricing;
      if (pricing) {
        pricing = pricing.filter(p => p.status === "Rejected");
      }
      return pricing;
    },
    sampleInvoice() {
      return `${process.env.VUE_APP_ACCOUNTS_URL}/storage/drumgolf/drumgolf-sample-invoice.pdf`;
    },
  },

  methods: {
    getStatusColor(status) {
      return status === "Accepted"
        ? "green"
        : status === "Option"
        ? "orange"
        : "red";
    },
    getStatusText(status) {
      return status.charAt(0).toUpperCase() + status.slice(1);
    },

    openDelete(price) {
      this.deleteDialog.price = price;
      this.deleteDialog.open = true;
    },

    resetDelete() {
      this.deleteDialog.loading = false;
      this.deleteDialog.open = false;
      this.deleteDialog.price = {};
      this.deleteDialog.fields.changeStatus = false;
    },

    saveDelete() {
      const appId = this.$route.params.id;
      const tourId = this.$route.params.tourId;
      const itineraryId = this.$route.params.itineraryId;
      this.deleteDialog.loading = true;

      if (this.requiresWarning() === true) {
        this.warningDialog.open = true;
        return;
      }

      this.$store
        .dispatch("drum/tours/deleteItineraryPrice", {
          appId,
          tourId,
          itineraryId,
          priceId: this.deleteDialog.price.id,
          fields: this.deleteDialog.fields,
        })
        .then(() => {
          this.resetDelete();
        })
        .catch(() => (this.loading = false));
    },

    requiresWarning() {
      if (this.warningDialog.confirmed === true) {
        return false;
      }

      const prices = _.cloneDeep(this.itinerary.pricing);
      const noAcceptedPrices = prices
        .filter(price => price.id !== this.deleteDialog.price.id)
        .every(price => {
          return price.status !== "Accepted";
        });

      if (noAcceptedPrices === true && this.itinerary.status === "Accepted") {
        return true;
      }

      return false;
    },

    confirmWarning(changeStatus) {
      this.deleteDialog.fields.changeStatus = changeStatus;
      this.warningDialog.open = false;
      this.warningDialog.confirmed = true;
      this.saveDelete();
    },
  },
};
</script>
