<template>
  <div>
    <v-dialog
      v-model="dialog"
      scrollable
      max-width="600px"
      @click:outside="resetForm()"
    >
      <v-card>
        <v-card-title class="headline"
          >{{ isEditing ? "Edit a" : "Add a new" }} Price</v-card-title
        >
        <v-divider></v-divider>
        <v-card-text class="pt-5 pb-0">
          <v-form @submit.prevent="saveForm" method="POST" id="price-form">
            <v-text-field
              label="Description"
              v-model="fields.description"
              outlined
            ></v-text-field>
            <v-text-field
              label="Amount"
              v-model="fields.amount"
              prepend-inner-icon="mdi-currency-gbp"
              outlined
            ></v-text-field>
            <v-text-field
              label="Quantity"
              v-model="fields.quantity"
              type="number"
              outlined
            ></v-text-field>
            <v-select
              label="Status"
              v-model="fields.status"
              :items="statuses"
              outlined
            ></v-select>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="white">
          <v-spacer></v-spacer>
          <v-btn color="secondary" text @click="resetForm()">Close</v-btn>
          <v-btn
            color="accent"
            text
            type="submit"
            :loading="loading"
            form="price-form"
            >Save</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="warningDialog.open" max-width="500px" persistent>
      <v-card>
        <v-card-title class="headline">Itinerary Status Change</v-card-title>
        <v-card-text>
          <p v-html="warningDialog.message"></p>
          <p class="mb-0">Do you agree with this assumption?</p>
        </v-card-text>
        <v-card-actions class="pl-6 pr-6 pb-6">
          <v-btn color="error" depressed @click="confirmWarning(false)"
            >No</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn color="success" depressed @click="confirmWarning(true)"
            >Yes</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import _ from "lodash";

export default {
  data() {
    return {
      dialog: false,
      loading: false,
      isEditing: false,
      statuses: ["Accepted", "Option", "Rejected"],
      fields: {
        status: "Option",
        description: "",
        quantity: null,
        amount: null,
        changeItineraryStatus: false,
      },
      errors: {},
      price: {},
      warningDialog: {
        open: false,
        loading: false,
        confirmed: false,
        message: "",
      },
    };
  },

  computed: {
    itinerary() {
      return this.$store.state.drum.tours["itinerary"];
    },
    tour() {
      return this.$store.state.drum.tours["tour"];
    },
  },

  methods: {
    openForm: function (price = null) {
      if (price !== null) {
        this.isEditing = true;
        this.price = price;
        this.fields.status =
          price.status.charAt(0).toUpperCase() + price.status.slice(1);
        this.fields.description = price.description;
        this.fields.quantity = price.quantity;
        this.fields.amount = price.amount;
      }

      this.dialog = true;
    },

    resetForm: function () {
      this.dialog = false;
      this.loading = false;
      this.isEditing = false;
      this.fields.status = "Option";
      this.fields.description = "";
      this.fields.quantity = null;
      this.fields.amount = null;
      this.fields.changeItineraryStatus = false;
      this.warningDialog = {
        open: false,
        loading: false,
        confirmed: false,
        message: "",
      };
    },

    saveForm: function () {
      this.loading = true;
      this.errors = {};

      const existingTourStatus = this.tour.status;

      if (this.requiresWarning() === true) {
        this.warningDialog.open = true;
        return;
      }

      let payload = {
        appId: this.$route.params.id,
        tourId: this.$route.params.tourId,
        itineraryId: this.$route.params.itineraryId,
        isEditing: this.isEditing,
        fields: this.fields,
      };

      if (this.isEditing) {
        payload.priceId = this.price.id;
      }

      this.$store
        .dispatch("drum/tours/saveItineraryPrice", {
          ...payload,
        })
        .then((resp) => {
          const changeStatus = this.fields.changeItineraryStatus;
          this.resetForm();

          if (
            changeStatus === true &&
            existingTourStatus !== resp.data.tour_status
          ) {
            this.$router.push({
              name: `module-drum-${resp.data.tour_status}s-itinerary-pricing`,
              pricing: {
                tourId: this.$route.params.tourId,
                itineraryId: this.$route.params.itineraryId,
              },
            });
          }
        })
        .catch((err) => {
          this.errors = err.response.data.errors;
          this.loading = false;
        });
    },

    requiresWarning: function () {
      if (this.warningDialog.confirmed === true) {
        return false;
      }

      const priceStatus = this.fields.status;
      const itineraryStatus = this.itinerary.status;
      const tourStatus = this.tour.status;

      if (priceStatus === "Accepted" && itineraryStatus === "Draft") {
        this.warningDialog.message = `Due to the status of this Price being <strong>Accepted</strong>, we
            have determined that the status of this Itinerary should also be
            changed to <strong>Accepted</strong>.`;

        if (tourStatus === "proposal") {
          this.warningDialog.message += ` We will also upgrade this Proposal to a <strong>Tour</strong>.`;
        }

        return true;
      }

      if (this.isEditing === false) {
        return false;
      }

      const prices = _.cloneDeep(this.itinerary.pricing);
      const noAcceptedPrices = prices
        .map((price) => {
          if (price.id === this.price.id) {
            price.status = priceStatus;
          }
          return price;
        })
        .every((price) => {
          return price.status !== "Accepted";
        });

      if (noAcceptedPrices === true && itineraryStatus === "Accepted") {
        this.warningDialog.message = `Due to the status of this Price being changed to <strong>${priceStatus}</strong> and as there are no other Prices that are Accepted, we have determined that the status of this Itinerary should be downgraded to <strong>Draft</strong>.`;

        if (tourStatus === "tour" && this.itinerary.payments.length === 0) {
          this.warningDialog.message += ` This Tour will also be changed back to a <strong>Proposal</strong>`;
        }

        return true;
      }

      return false;
    },

    confirmWarning: function (changeStatus) {
      this.fields.changeItineraryStatus = changeStatus;
      this.warningDialog.open = false;
      this.warningDialog.confirmed = true;
      this.saveForm();
    },
  },
};
</script>